import { KeyboardEventHandler, ReactNode, useEffect, useRef } from "react";

import { Popover, PopoverBody, PopoverContent, PopoverTrigger } from "@chakra-ui/popover";
import { Box, ButtonGroup, Column, Heading, LinkButton, Paragraph, Row, SearchInput, Text } from "@hightouchio/ui";
import { useVirtualizer } from "@tanstack/react-virtual";
import pluralize from "pluralize";
import { Portal } from "react-portal";

import { useArrowKeyFocus } from "src/hooks/use-arrow-key-focus";
import { AudienceParent, Condition } from "src/types/visual";
import { WarningSVG } from "src/ui/icons/new-icons";
import { TextWithTooltip } from "src/ui/text";

import { Indices } from "../../../../../design";
import {
  FilterOption,
  CONTENT_SECTION_WIDTH_PX,
  MAX_POPOVER_HEIGHT_PX,
  PADDING,
  TAB_SECTION_WIDTH_PX,
  TABS,
  MetadataIcons,
  MAX_INPUT_WIDTH_PX,
} from "./constants";
import { FilterPopoverOption } from "./filter-popover-option";
import { FilterPopoverTab } from "./filter-popover-tab";
import { FilterSelectButton } from "./filter-select-button";
import { useConditionSelect } from "./use-condition-select";
import { getSearchPlaceholderText, getIconAndBackground, isColumnSelected, getSetupLink, getMetadata } from "./utils";

export interface FilterPopoverProps<TCondition> {
  condition?: TCondition;
  hasError?: boolean;
  triggerButton?: ReactNode;
  isDisabled?: boolean;
  parent: AudienceParent | undefined | null;
  onChange: (updates: TCondition) => void;
}

export function FilterPopover<TCondition extends Condition>({
  condition,
  isDisabled = false,
  hasError = false,
  onChange,
  triggerButton,
  parent,
}: FilterPopoverProps<TCondition>): JSX.Element {
  const searchRef = useRef<HTMLInputElement>(null);

  // virtual parent container
  const optionsContainer = useRef<HTMLDivElement>(null);

  const [focusedTabIndex, setFocusedTabIndex] = useArrowKeyFocus(TABS.length);

  const {
    columns,
    filter,
    search,
    selectedColumn,
    selectedColumnIndex,
    onFilterChange,
    onReset,
    onResetSelectedTab,
    onSearch,
    onSelectColumn,
  } = useConditionSelect({
    condition,
  });

  const virtualizer = useVirtualizer({
    count: columns.length,
    getScrollElement: () => optionsContainer.current,
    estimateSize: () => 48,
  });

  const items = virtualizer.getVirtualItems();

  useEffect(() => {
    if (columns.length > 0) {
      if (selectedColumnIndex > -1) {
        virtualizer.scrollToIndex(selectedColumnIndex);
      } else {
        virtualizer.scrollToIndex(0);
      }
    }
  }, [selectedColumnIndex, columns]);

  const changeFocus: (index: number) => KeyboardEventHandler = (index) => (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      setFocusedTabIndex(index - 1);
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      setFocusedTabIndex(index + 1);
    }
  };

  const placeholderText = getSearchPlaceholderText(filter);
  const missingColumnTypeText = filter === FilterOption.All ? "column" : filter.toLowerCase();
  let buttonContent: ReactNode = selectedColumn?.label;

  const metadata = getMetadata(selectedColumn);

  if (selectedColumn?.label && metadata) {
    buttonContent = (
      <Row maxWidth={`${MAX_INPUT_WIDTH_PX}px`} gap={2}>
        <TextWithTooltip message={selectedColumn.label}>{selectedColumn.label} </TextWithTooltip>
        <Row
          as={Text}
          align="center"
          color="text.secondary"
          overflow="hidden"
          sx={{ svg: { height: "12px", width: "12px", mr: 1, flexShrink: 0 } }}
        >
          {MetadataIcons[metadata.type]}{" "}
          <TextWithTooltip color="text.secondary" message={metadata.modelName}>
            {metadata.modelName}
          </TextWithTooltip>
        </Row>
      </Row>
    );
  }

  return (
    <Popover
      preventOverflow
      initialFocusRef={searchRef}
      placement="bottom-start"
      returnFocusOnClose={false}
      onOpen={onResetSelectedTab}
      onClose={onReset}
    >
      {({ isOpen, onClose }) => (
        <>
          <Row sx={{ "&>div": { alignSelf: "start" } }}>
            <PopoverTrigger>
              {triggerButton ? (
                triggerButton
              ) : (
                <FilterSelectButton {...getIconAndBackground(selectedColumn?.type)} hasError={hasError} isDisabled={isDisabled}>
                  {selectedColumn ? buttonContent : "Select a property..."}
                </FilterSelectButton>
              )}
            </PopoverTrigger>
          </Row>

          <Portal>
            <Box sx={{ ">div": { zIndex: Indices.Modal } }}>
              <PopoverContent width="auto">
                <PopoverBody p={0} flex="1 0 auto">
                  <Row minHeight={0} flex={1} maxHeight={`${MAX_POPOVER_HEIGHT_PX}px`} pl={PADDING} gap={PADDING}>
                    <Column
                      flex="0 0 auto"
                      role="tablist"
                      borderRight="1px"
                      borderColor="base.border"
                      overflowY="auto"
                      overflowX="visible"
                      py={PADDING}
                      width={`${TAB_SECTION_WIDTH_PX}px`}
                    >
                      {TABS.map(({ type, ...tab }, index) => (
                        <FilterPopoverTab
                          key={tab.label}
                          {...tab}
                          isFocused={focusedTabIndex === index}
                          isSelected={filter === type}
                          onKeyDown={changeFocus(index)}
                          onClick={() => {
                            onFilterChange(type);
                            searchRef.current?.focus();
                          }}
                        />
                      ))}
                    </Column>
                    <Column role="tabpanel" flex="0 0 auto" width={`${CONTENT_SECTION_WIDTH_PX}px`} py={PADDING} pr={PADDING}>
                      <SearchInput
                        ref={searchRef}
                        placeholder={placeholderText}
                        mb={2}
                        width="100%"
                        value={search}
                        onChange={onSearch}
                      />
                      <Column ref={optionsContainer} height="344px" overflowY="auto" width={`${CONTENT_SECTION_WIDTH_PX}px`}>
                        {columns.length === 0 && (
                          <Column flex={1} minHeight={0} justify="center" textAlign="center">
                            <Box
                              mx="auto"
                              color="warning.base"
                              width={12}
                              height={12}
                              borderRadius="full"
                              bg="warning.background"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <WarningSVG />
                            </Box>

                            <Column mt={4}>
                              <Heading>No {pluralize(missingColumnTypeText)} found</Heading>

                              <Paragraph mt={2} mx={8}>
                                No columns were found.
                                {search.length > 0
                                  ? ` Please check your search or create a new ${missingColumnTypeText}.`
                                  : null}{" "}
                                Would you like to create a new {missingColumnTypeText}?
                              </Paragraph>
                            </Column>

                            <ButtonGroup mx="auto" mt={8}>
                              <LinkButton href={getSetupLink(filter, parent?.id)} variant="primary">
                                Go to setup
                              </LinkButton>
                            </ButtonGroup>
                          </Column>
                        )}
                        <Box
                          style={{
                            height: virtualizer.getTotalSize(),
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <Box
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              transform: `translateY(${items?.[0]?.start ?? 0}px)`,
                            }}
                          >
                            {isOpen &&
                              items.map((virtualItem) => {
                                const column = columns?.[virtualItem.index];
                                const displayProps = getIconAndBackground(column?.type);

                                if (!column || !displayProps) {
                                  return null;
                                }

                                return (
                                  <FilterPopoverOption
                                    key={virtualItem.key}
                                    ref={virtualizer.measureElement}
                                    data-index={virtualItem.index}
                                    {...displayProps}
                                    isSelected={virtualItem.index === selectedColumnIndex}
                                    label={column.label}
                                    description={column?.description}
                                    metadata={getMetadata(column)}
                                    onClick={() => {
                                      onClose();
                                      onChange(onSelectColumn(column) as any);
                                    }}
                                  />
                                );
                              })}
                          </Box>
                        </Box>
                      </Column>
                    </Column>
                  </Row>
                </PopoverBody>
              </PopoverContent>
            </Box>
          </Portal>
        </>
      )}
    </Popover>
  );
}
